<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <!-- <b-col md="2" class="text-left">
          <b-row>
            <b-col md="12">
              <b-media
                class="d-flex justify-content-center mb-0 flex-wrap"
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    :src="model.logo"
                    class="mb-1"
                    size="90px"
                  ></b-avatar>
                </b-media-aside>
              </b-media>
            </b-col>
          </b-row>
        </b-col> -->
        <b-col md="12" class="mt-1">
          <!-- <b-row>
            <b-col md="2">
              <p
                class="p-1 text-center text-capotalize font-weight-bolder"
                style="border: 2px solid #333; border-radius: 5px"
              >
                {{ model.status }}
              </p>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col md="6">
              <p class="mb-0"><b>Name</b></p>
              <p>{{ model.name }}</p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Phone Number</b></p>
              <p>{{ model.phoneNumber }}</p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Address</b></p>
              <p>{{ model.address }}</p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Slug</b></p>
              <p>{{ model.slug }}</p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>City</b></p>
              <p>{{ model.city.name }}</p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>industries</b></p>
              <p>{{ model.industry.name }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'company' }"
      >
        Back
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    vSelect,
    BTab,
    BTabs,
    BModal,
    BAvatar,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      config: {
        api: "companies",
      },
      model: {},
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data;
      });
    },
  },
};
</script>
